@media (max-width: 1024px) {
  .non-mobile {
    display: none;
  }
}

@media (min-width: 768px) {
  .not-mobile {
    display: block !important;
  }

  .not-tablet {
    display: none !important;
  }

  .fixedHeaderSplash {
    width: calc(100% - 140px);
    margin-top: 30px;
    padding-left: 40px;
    padding-right: 70px;
  }

  #section1 {
    opacity: 0 !important;
    z-index: -1 !important;
  }

  #section0 {
    z-index: 0 !important;
    background: none !important;
  }

  .microText.sectionTextLarge, .sectionTextLarge {
    font-size: 32px;
  }

  .sectionText {
    max-width: 473px;
  }

  #landingJoshLogo {
    transform: scale(.8);
  }

  .landingContainer1 {
    height: calc(100% - 60px);
    width: calc(100% - 60px);
  }

  #secondCopy {
    display: none;
  }

  .landingPlayButton {
    opacity: 0;
  }

  .landingContainer2 {
    width: 75%;
    height: 42.1875vw;
  }

  .dealerapp-table {
    min-width: 400px;
  }

  .heyJoshContainer {
    font-size: 2.5rem;
    left: 8vw;
  }

  #section2 {
    background: url("https://d35408cyocioye.cloudfront.net/new3/josh-getjosh-tv.jpg");
  }

  .microText, .microTextSecondary, #transMicro, #brandWall, .micro-anim {
    position: fixed !important;
  }

  .microText {
    top: 100px;
  }

  .microTextSecondary {
    left: unset;
    width: 40vw;
    text-align: left !important;
    font-size: 20px !important;
  }

  .microTextSecondary:nth-of-type(2n) {
    right: 5vw;
  }

  .microTextSecondary:nth-of-type(2n+1) {
    left: 5vw;
  }

  .microTextSecondary:nth-of-type(-n+2) {
    top: 30% !important;
  }

  .microTextSecondary:nth-of-type(n+3) {
    top: 60% !important;
  }

  #microTextSecondary1.animated, #microTextSecondary2.animated, #microTextSecondary3.animated, #microTextSecondary4.animated {
    animation: none;
  }

  .wrapper {
    height: 50vh !important;
  }

  #sectionFoot {
    height: 100vh !important;
  }

  .nextUp {
    bottom: 50vh;
  }

  .joinText {
    width: auto;
    font-size: 10px;
    display: inline-block;
    top: 25% !important;
    left: 75% !important;
  }

  #emailform {
    width: 260px;
  }

  .bottomFooter {
    height: 25% !important;
  }

  .bottomFooter img {
    opacity: .5;
    transform: scale(.7);
    padding-right: 2vw !important;
  }

  .socialIcons img {
    margin-top: 8px !important;
  }

  .bottomFooterLayout {
    flex-direction: row !important;
    display: flex !important;
  }

  .fullFooter {
    height: 50vh !important;
  }

  .footerLeftLinks {
    top: 37.5%;
    margin-left: 13vw !important;
  }

  .footerRightLinks {
    top: 37.5%;
    right: unset;
    margin-left: 30vw !important;
  }
}

@media (min-width: 1024px) {
  .not-tablet {
    display: block !important;
  }

  .non-desk, #floating-header {
    display: none;
  }

  .fixedHeaderPosition {
    width: calc(100% - 100px);
    margin-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    position: fixed !important;
  }

  .fixedHeaderSplash {
    width: calc(100% - 140px) !important;
    margin-top: 60px !important;
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

  #fullNavListLeft {
    margin-left: 12vw !important;
    padding-left: 0 !important;
  }

  #fullNavListRight {
    left: 75.5vw !important;
  }

  .fullNavList {
    margin-top: 22.5vh !important;
  }

  .fullNavText a {
    font-size: 30px !important;
  }

  .fullNavText {
    margin-bottom: 1rem !important;
  }

  .horizontalAboutLine:before {
    border: 1px solid #fff !important;
  }

  #fullNavListLeft li:nth-child(3) {
    margin-top: 0 !important;
  }

  .horizontalAboutLine {
    height: 14.5rem !important;
    top: .5rem !important;
  }

  #fullNavListMid {
    margin-top: 22.5vh !important;
    left: 38vw !important;
  }

  .fullNavText a:hover {
    opacity: 1 !important;
  }

  .fullNavText a:active {
    opacity: .9 !important;
  }

  .hoverLink:before {
    content: "";
    width: 100%;
    height: 1px;
    visibility: hidden;
    background-color: #fff;
    transition: all .2s ease-in-out;
    position: absolute;
    bottom: -1px;
    left: 0;
    transform: scaleX(0);
  }

  .hoverLink:hover:before {
    visibility: visible;
    transform: scaleX(1);
  }

  .microText.sectionTextLarge, .sectionTextLarge {
    font-size: 40px;
  }

  .sectionText {
    max-width: 700px;
  }

  #landingJoshLogo {
    transform: scale(.95);
  }

  .landingContainer1 {
    height: calc(100% - 80px);
    width: calc(100% - 80px);
  }

  .landingContainer2 {
    width: 60%;
    height: 33.75vw;
  }

  #secondCopy {
    height: 100%;
    padding-top: 50%;
    display: none;
  }

  .landingPlayButton {
    opacity: 0;
  }

  .dealerapp-table {
    min-width: 600px;
  }

  .heyJoshContainer {
    font-size: 3rem;
    left: 10vw;
  }

  .heyJoshRequest {
    border-bottom: 2px dashed #fff;
  }

  .microTextSecondary {
    width: 17vw !important;
  }

  .microTextSecondary:nth-of-type(2n) {
    right: 17vw !important;
  }

  .microTextSecondary:nth-of-type(2n+1) {
    left: 17vw !important;
  }

  #microTextSecondary1.animated {
    animation: 1s centerOutFirst !important;
  }

  #microTextSecondary2.animated {
    animation: 1s centerOutSecond !important;
  }

  #microTextSecondary3.animated {
    animation: 1s centerOutThird !important;
  }

  #microTextSecondary4.animated {
    animation: 1s centerOutFourth !important;
  }

  .joinText {
    font-size: 12px;
  }

  .bottomFooter img {
    transform: scale(1) !important;
  }

  .footerTextContainer {
    bottom: 15vh !important;
    left: 50% !important;
    top: unset !important;
    width: 50% !important;
    cursor: pointer !important;
    position: absolute !important;
    transform: translate(-50%, -50%) !important;
  }

  .next-flex {
    flex-direction: row;
  }

  .next-item {
    flex-direction: row;
    margin-right: 80px;
  }

  .next-title {
    margin-bottom: 0;
  }

  .bottom-container {
    width: 95vw;
    flex-direction: row;
  }

  .bottom-social-container {
    margin-bottom: 0;
  }

  .bottom-link-container.mobile-bottom-links {
    display: none;
  }

  .bottom-link-container.desk-bottom-links {
    display: flex;
  }

  .bottom-link-container a {
    margin-right: 20px;
  }

  .bottom-link.bottom-privacy {
    margin: 0;
  }

  .bottom-email-container {
    height: 130px;
    align-items: center;
    margin-bottom: 0;
    display: flex;
  }

  .info-container {
    width: 95vw;
    max-width: none;
    flex-direction: row;
    padding: 120px 0;
  }

  .info-link-container {
    flex-direction: row;
  }

  .info-link-col {
    align-self: unset;
  }

  .info-link-col:nth-child(2) {
    margin-left: 50px;
  }

  .info-link-line {
    display: block;
  }

  .info-links {
    align-items: flex-start;
    margin-left: 20px;
    display: flex;
  }

  .info-story-container {
    margin-bottom: 0;
  }

  .info-story-content {
    width: 25vw;
    text-align: left;
  }

  .info-award-container {
    margin: unset;
  }

  .next-container {
    width: 25%;
  }

  .cookie-banner {
    flex-direction: row;
    padding: 40px !important;
  }

  .cookie-close {
    margin-left: 70px;
  }

  #main-nav {
    box-shadow: unset;
    box-sizing: border-box;
    background-color: #0000;
    flex-direction: row;
    padding: 0 16px;
  }

  .hamburger {
    display: none;
  }

  .dog-logo {
    transform: none;
  }

  .link-cont {
    flex-direction: column;
    margin-bottom: 0;
  }

  .int-links, .about-links, .product-links {
    margin-right: 40px;
  }

  .login-links {
    text-align: right;
    position: absolute;
    right: 30px;
  }

  .links.initial {
    cursor: pointer;
    display: flex;
  }

  .links.initial.scrolled {
    visibility: visible;
    animation: .3s forwards fadeLinksOut;
  }

  #main-nav.expandedNav .links.scrolled {
    visibility: visible;
  }

  #main-nav.expandedNav {
    height: 350px;
  }

  #main-nav.scrolled {
    width: 120px;
    padding-bottom: 0 !important;
  }

  #main-nav.scrolled.expandedNav {
    width: calc(100vw - 20px);
  }

  .links.scrolled {
    visibility: hidden;
  }

  @keyframes fadeLinksOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      visibility: hidden;
    }
  }

  .links.initial .link-header {
    color: #fff;
    cursor: pointer;
    opacity: .8;
  }

  .links.initial .link-header:hover {
    color: #fff;
    cursor: pointer;
    opacity: 1;
  }

  .links {
    width: calc(100vw - 206px);
    height: 100%;
    flex-direction: row;
    margin-top: 0;
    margin-bottom: 0;
    left: 55px;
  }

  .links .link-header {
    font-size: 12px;
  }

  .nav-arrow {
    display: none;
  }

  #main-nav.expandedNav .sub-links {
    opacity: 1;
  }

  .sub-links {
    opacity: 0;
    margin-top: 30px;
    margin-left: 0;
  }

  .sub-links a {
    width: unset;
    margin-bottom: 20px;
    font-size: 12px;
    color: #9a9a95 !important;
  }
}

@media (min-width: 1124px) {
  .bottom-container, .info-container {
    width: 85vw;
  }
}

@media (min-width: 1440px) {
  .microText.sectionTextLarge, .sectionTextLarge {
    font-size: 40px;
  }

  .sectionText {
    max-width: 727px;
  }

  #landingJoshLogo {
    transform: scale(1);
  }

  .landingContainer1 {
    height: calc(100% - 80px);
    width: calc(100% - 80px);
  }

  #secondCopy {
    display: none;
  }

  .landingContainer2 {
    width: 50%;
    height: 28.125vw;
  }

  .landingPlayButton {
    opacity: 0;
  }

  .heyJoshContainer {
    font-size: 3.5rem;
    left: 13vw;
  }

  .joinText {
    font-size: 15px;
  }
}

/*# sourceMappingURL=index.d31a606f.css.map */
