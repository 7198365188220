@media (max-width: 1024px) {
  .non-mobile {
    display: none;
  }
}

/*TABLET*/
@media (min-width: 768px) {
  .not-mobile {
    display: block !important;
  }
  .not-tablet {
    display: none !important;
  }

  /*HEADER*/
  .fixedHeaderSplash {
    width: calc(100% - 140px);
    margin-top: 30px;
    padding-left: 40px;
    padding-right: 70px;
  }
  /*FIRST LANDING*/
  #section1 {
    opacity: 0 !important;
    z-index: -1 !important;
  }
  #section0 {
    background: none !important;
    z-index: 0 !important;
  }
  .microText.sectionTextLarge,
  .sectionTextLarge {
    font-size: 32px;
  }
  .sectionText {
    max-width: 473px;
  }
  #landingJoshLogo {
    transform: scale(0.8);
  }
  .landingContainer1 {
    height: calc(100% - 60px);
    width: calc(100% - 60px);
  }
  /*SECOND LANDING*/
  #secondCopy {
    display: none;
  }
  .landingPlayButton {
    opacity: 0;
  }
  .landingContainer2 {
    width: 75%;
    height: calc(0.5625 * 75vw);
  }
  /*Dealer app form*/
  .dealerapp-table {
    min-width: 400px;
  }
  /*HEY JOSH*/
  .heyJoshContainer {
    left: 8vw;
    font-size: 2.5rem;
  }
  #section2 {
    background: url("https://d35408cyocioye.cloudfront.net/new3/josh-getjosh-tv.jpg");
  }
  /*LAST MICRO SECTION*/
  .microText,
  .microTextSecondary,
  #transMicro,
  #brandWall,
  .micro-anim {
    position: fixed !important;
  }
  .microText {
    top: 100px;
  }
  .microTextSecondary {
    font-size: 20px !important;
    text-align: left !important;
    left: unset;
    width: 40vw;
  }
  .microTextSecondary:nth-of-type(even) {
    right: 5vw;
  }
  .microTextSecondary:nth-of-type(odd) {
    left: 5vw;
  }
  .microTextSecondary:nth-of-type(-n + 2) {
    top: 30% !important;
  }
  .microTextSecondary:nth-of-type(n + 3) {
    top: 60% !important;
  }
  #microTextSecondary1.animated {
    animation: none;
  }
  #microTextSecondary2.animated {
    animation: none;
  }
  #microTextSecondary3.animated {
    animation: none;
  }
  #microTextSecondary4.animated {
    animation: none;
  }
  .wrapper {
    height: 50vh !important;
  }
  /*FOOTER*/
  #sectionFoot {
    height: 100vh !important;
  }
  .nextUp {
    bottom: 50vh;
  }
  .joinText {
    font-size: 10px;
    left: 75% !important;
    top: 25% !important;
    width: auto;
    display: inline-block;
  }
  #emailform {
    width: 260px;
  }
  .btn-bg {
    /* margin-left: 10px !important;
    margin-top: 0px !important; */
  }
  /* #email_status.enterText{
    left: 65% !important;
    top: 50% !important;
    transform: translateX(0) !important;
  } */
  .bottomFooter {
    height: 25% !important;
  }
  .bottomFooter img {
    transform: scale(0.7);
    padding-right: 2vw !important;
    opacity: 0.5;
  }
  .socialIcons img {
    margin-top: 8px !important;
  }
  .bottomFooterLayout {
    display: flex !important;
    flex-direction: row !important;
  }
  .fullFooter {
    height: 50vh !important;
  }
  .footerLeftLinks {
    margin-left: 13vw !important;
    top: 37.5%;
  }
  .footerRightLinks {
    margin-left: 30vw !important;
    top: 37.5%;
    right: unset;
  }
}

/*DESKTOP*/
@media (min-width: 1024px) {
  .not-tablet {
    display: block !important;
  }
  .non-desk {
    display: none;
  }
  #floating-header {
    display: none;
  }

  /*NAV*/
  .fixedHeaderPosition {
    width: calc(100% - 100px);
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 50px;
    position: fixed !important;
  }
  .fixedHeaderSplash {
    width: calc(100% - 140px) !important;
    margin-top: 60px !important;
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  #fullNavListLeft {
    margin-left: 12vw !important;
    padding-left: 0 !important;
  }
  #fullNavListRight {
    left: 75.5vw !important;
  }
  .fullNavList {
    margin-top: 22.5vh !important;
  }
  .fullNavText a {
    font-size: 30px !important;
  }
  .fullNavText {
    margin-bottom: 1rem !important;
  }
  .horizontalAboutLine::before {
    border: solid 1px #ffffff !important;
  }
  #fullNavListLeft li:nth-child(3) {
    margin-top: 0 !important;
  }
  .horizontalAboutLine {
    height: 14.5rem !important;
    top: 0.5rem !important;
  }
  #fullNavListMid {
    margin-top: 22.5vh !important;
    left: 38vw !important;
  }
  .fullNavText a:hover {
    opacity: 1 !important;
  }
  .fullNavText a:active {
    opacity: 0.9 !important;
  }
  .hoverLink::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -1px;
    left: 0;
    background-color: #fff;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
  }
  .hoverLink:hover::before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  /*FIRST LANDING*/
  .microText.sectionTextLarge,
  .sectionTextLarge {
    font-size: 40px;
  }
  .sectionText {
    max-width: 700px;
  }
  #landingJoshLogo {
    transform: scale(0.95);
  }
  .landingContainer1 {
    height: calc(100% - 80px);
    width: calc(100% - 80px);
  }
  /*SECOND LANDING*/
  .landingContainer2 {
    width: 60%;
    height: calc(0.5625 * 60vw);
  }
  #secondCopy {
    padding-top: 50%;
    height: 100%;
    display: none;
  }
  .landingPlayButton {
    opacity: 0;
  }
  /*Dealer app form*/
  .dealerapp-table {
    min-width: 600px;
  }
  /*HEY JOSH*/
  .heyJoshContainer {
    left: 10vw;
    font-size: 3rem;
  }
  .heyJoshRequest {
    border-bottom: 2px #fff dashed;
  }
  /*LAST MICRO SECTION*/
  .microTextSecondary {
    width: 17vw !important;
  }
  .microTextSecondary:nth-of-type(even) {
    right: 17vw !important;
  }
  .microTextSecondary:nth-of-type(odd) {
    left: 17vw !important;
  }
  #microTextSecondary1.animated {
    animation: centerOutFirst 1s !important;
  }
  #microTextSecondary2.animated {
    animation: centerOutSecond 1s !important;
  }
  #microTextSecondary3.animated {
    animation: centerOutThird 1s !important;
  }
  #microTextSecondary4.animated {
    animation: centerOutFourth 1s !important;
  }
  /*FOOTER*/
  .joinText {
    font-size: 12px;
  }
  .bottomFooter img {
    transform: scale(1) !important;
  }
  .footerTextContainer {
    bottom: 15vh !important;
    position: absolute !important;
    transform: translate(-50%, -50%) !important;
    left: 50% !important;
    top: unset !important;
    width: 50% !important;
    cursor: pointer !important;
  }
  .next-flex {
    flex-direction: row;
  }
  .next-item {
    margin-right: 80px;
    flex-direction: row;
  }
  .next-title {
    margin-bottom: 0;
  }

  .bottom-container {
    width: 95vw;
    flex-direction: row;
  }
  .bottom-social-container {
    margin-bottom: 0;
  }
  .bottom-link-container.mobile-bottom-links {
    display: none;
  }
  .bottom-link-container.desk-bottom-links {
    display: flex;
  }
  .bottom-link-container a {
    margin-right: 20px;
  }
  .bottom-link.bottom-privacy {
    margin: 0;
  }

  .bottom-email-container {
    margin-bottom: 0;
    height: 130px;
    display: flex;
    align-items: center;
  }

  .info-container {
    width: 95vw;
    padding: 120px 0;
    flex-direction: row;
    max-width: none;
  }
  .info-link-container {
    flex-direction: row;
  }
  .info-link-col {
    align-self: unset;
  }
  .info-link-col:nth-child(2) {
    margin-left: 50px;
  }
  .info-link-line {
    display: block;
  }
  .info-links {
    display: flex;
    align-items: flex-start;
    margin-left: 20px;
  }
  .info-story-container {
    margin-bottom: 0;
  }
  .info-story-content {
    width: 25vw;
    text-align: left;
  }
  .info-award-container {
    margin: unset;
  }

  .next-container {
    width: 25%;
  }
  .cookie-banner {
    padding: 40px !important;
    flex-direction: row;
  }
  .cookie-close {
    margin-left: 70px;
  }

  /* MAIN NAV */
  #main-nav {
    flex-direction: row;
    padding: 0 16px;
    padding-bottom: 0;
    background-color: transparent;
    box-shadow: unset;
    box-sizing: border-box;
  }
  .hamburger {
    display: none;
  }
  .dog-logo {
    /* margin-left: 30px; */
    transform: none;
  }
  .link-cont {
    flex-direction: column;
    margin-bottom: 0;
  }
  .int-links,
  .about-links,
  .product-links {
    margin-right: 40px;
  }
  .login-links {
    right: 30px;
    position: absolute;
    text-align: right;
  }
  .links.initial {
    display: flex;
    /* visibility: visible; */
    cursor: pointer;
  }
  .links.initial.scrolled {
    animation: 0.3s fadeLinksOut forwards;
    visibility: visible;
  }
  #main-nav.expandedNav .links.scrolled {
    visibility: visible;
  }
  #main-nav.expandedNav {
    height: 350px;
  }
  #main-nav.scrolled {
    width: 120px;
    padding-bottom: 0 !important;
  }
  #main-nav.scrolled.expandedNav {
    width: calc(100vw - 20px);
    /* padding-bottom: 40px !important; */
  }
  .links.scrolled {
    visibility: hidden;
  }
  @keyframes fadeLinksOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
  .links.initial .link-header {
    color: #fff;
    cursor: pointer;
    opacity: 0.8;
  }
  .links.initial .link-header:hover {
    color: #fff;
    cursor: pointer;
    opacity: 1;
  }
  .links {
    left: 55px;
    width: calc(100vw - 206px);
    flex-direction: row;
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
  }
  .links .link-header {
    font-size: 12px;
  }
  .nav-arrow {
    display: none;
  }
  #main-nav.expandedNav .sub-links {
    opacity: 1;
  }
  .sub-links {
    margin-top: 30px;
    margin-left: 0;
    opacity: 0;
  }
  .sub-links a {
    color: #9a9a95 !important;
    font-size: 12px;
    margin-bottom: 20px;
    width: unset;
  }
}

@media (min-width: 1124px) {
  .bottom-container {
    width: 85vw;
  }
  .info-container {
    width: 85vw;
  }
}
/*HD*/
@media (min-width: 1440px) {
  /*FIRST LANDING*/
  .microText.sectionTextLarge,
  .sectionTextLarge {
    font-size: 40px;
  }
  .sectionText {
    max-width: 727px;
  }
  #landingJoshLogo {
    transform: scale(1);
  }
  .landingContainer1 {
    height: calc(100% - 80px);
    width: calc(100% - 80px);
  }
  /*SECOND LANDING*/
  #secondCopy {
    display: none;
  }
  .landingContainer2 {
    width: 50%;
    height: calc(0.5625 * 50vw);
  }
  .landingPlayButton {
    opacity: 0;
  }
  /*HEY JOSH*/
  .heyJoshContainer {
    left: 13vw;
    font-size: 3.5rem;
  }
  /*FOOTER*/
  .joinText {
    font-size: 15px;
  }
}
